import React from 'react';
import { useStore } from './SocketHook';
import QRCode from 'qrcode.react';

const Home = () => {    
    const { state } = useStore();
    return <>
        { state.ip && <QRCode
            level="Q"
            fgColor="#000"
            bgColor="#fff"
            size={160}
            value={`http://${state.ip}:${process.env.REACT_APP_PORT}`}
            id="main__qr_code"
            data-testid="qr-code" />
        }
    </>
}

export default Home

import React, { createContext, useContext, useEffect, useReducer } from 'react';
import io from 'socket.io-client';

const SOCKET_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:6066' : process.env.REACT_APP_SOCKET_URL

const socket = io(SOCKET_URL, {
// const socket = io(SOCKET_URL, {
    // transports: ["websocket", "polling"],
    withCredentials: true,
    // extraHeaders: {
    //   "my-custom-header": "abcd"
    // }
});

export const dm = (id, msg) => socket.emit("dm", id, msg)

const SocketStore  = createContext();
SocketStore.displayName = 'Store';
const { Provider } = SocketStore;

export const setUsers = users => ({ type: 'SET_USERS', payload: users })
export const setSocket = socket => ({ type: 'SET_SOCKET', payload: socket })
const setSocketConfig = payload => ({ type: 'SET_SOCKET_CONFIG', payload })
const addDm = payload => ({ type: 'ADD_DM', payload })

const initialState = {
    ip: null,
    myId: null,
    myUsername: null,
    users: [],
    socket,
    dms: [],
    rooms: {}
}

const reducer = (state, action) => {
    const { type, payload } = action;

    console.log(action)

    if(type === 'SET_USERS') return {
        ...state,
        users: payload.filter(user => user.id !== state.myId)
    }
    if(type === 'SET_SOCKET') return { ...state, socket: payload }
    if(type === 'ADD_DM') {
        console.log('add dm', payload)
        const { id, messages } = payload;
        return {
            ...state,
            rooms: {
                ...state.rooms,
                [id]: {
                    messages
                }
            }
        }
    }
    if(type === 'SET_SOCKET_CONFIG') {
        const user = payload.user;
        if(!user) return {
            ...state,
            users: payload.users,
            myId: 'geenidee',
            myUsername: 'No Username',
            ip: payload.ip
        }

        return {
            ...state,
            users: payload.users,
            myId: payload.user.id,
            myUsername: payload.user.username,
            ip: payload.ip
        }
    }

}

export const useStore = () => useContext(SocketStore);

export const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    
    useEffect(() => {

        socket.on("connect", payload => console.log("connect", payload));
        socket.on("disconnect", payload => console.log("disconnect", payload));
        socket.on("message", payload => {
            dispatch(setSocketConfig(JSON.parse(payload)))
        });  
        socket.on("sockets", payload => dispatch(setUsers(JSON.parse(payload).users)))
        socket.on("init", payload => {
            dispatch(setSocketConfig(JSON.parse(payload)))
        })
        socket.on("dm", payload => {
            dispatch(addDm(JSON.parse(payload)))
        });

    }, [])
    
    return <Provider value={{ state, dispatch }}>{children}</Provider>
}

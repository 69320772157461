import styled from 'styled-components'
import { NavLink } from "react-router-dom";
export const StylingSpan = styled.span`
a {
    text-decoration: none;
    cursor: pointer;
    display: block;
    color: #fff;
    padding: 5px 10px;

    &:hover {
        background: rgba(255,255,255,0.1);
    }

    &.active {
        font-weight: bold;
    }
}
`

const UserButton = ({ user }) => {
    const { username, id } = user;
    return <StylingSpan>
        <NavLink
            className={({ isActive }) => isActive ? 'active' : ''}
            to={id}
            key={id}>{username} ({id.substring(0,3)})</NavLink>
    </StylingSpan>
}

export default UserButton

import styled from "styled-components";
import { useStore } from "../SocketHook"

const UserName = styled.span`
font-weight: bold;
font-size: 12px;
margin-bottom: 5px;
display: block;
color: rgb(100,80,90);
`

const MessageContainer = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 25px;
align-items: flex-${({ isItMe }) => isItMe ? 'end' : 'start'}
`

const Message = ({ message, timestamp, from}) => {
    const { state } = useStore();
    return <MessageContainer isItMe={from.id === state.myId}>
        <UserName>{from.id === state.myId ? 'you' : from.username}</UserName>
        {message}
    </MessageContainer>
}

export default Message

import styled from 'styled-components';
import UserButton from "./UserButton";
import { useStore } from '../SocketHook';
import { Link } from "react-router-dom";
import { StylingSpan } from './UserButton';

const Navbar = styled.nav`
display: flex;
flex-direction: column;
padding: 10px;
background: rgb(80,50,60);
min-width: 220px;
`

const SideNav = () => {
    const { state } = useStore()
    return <Navbar>
        <StylingSpan><Link to="/">Home</Link></StylingSpan>
        { state.users.length > 0
            ? state.users
                .filter(({ id }) => id !== state.myId)
                .map((user, index) => <UserButton key={index} user={user} />)
            : <span>No users online</span>
        }
    </Navbar>
}

export default SideNav

import styled from 'styled-components';
import SideBar from './components/SideBar';
import TopBar from './components/TopBar';
import General from './General';
import Channel from './Channel';
import NotFound from './NotFound';
import { Routes, Route } from "react-router-dom";
import Home from './Home';

const Layout = styled.div`
display: flex;
flex-direction: column;
width: 100%;
min-height: 100vh;
`

const Container = styled.div`
display: grid;
grid-template-columns: 1fr 5fr;
background: #f2f2f2;
height: calc(100vh - 40px);
`

const MainScreen = styled.main`
position: relative;
padding: 50px;
`

function App() {
  
  return (<Layout>
    <TopBar />
    <Container>
      <SideBar />
      <MainScreen>
        <Routes>
          <Route exact path="/" element={<General />}>
            <Route path="/" element={<Home />} />
            <Route path=":channel" element={<Channel />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </MainScreen>
    </Container>
  </Layout>
  );
}

export default App;

import styled from 'styled-components';
import { useStore } from '../SocketHook';

const Container = styled.header`
padding: 0 20px;
background: rgb(60,30,40);
width: 100vw;
height: 40px;
color: #fff;
display: flex;
justify-content: space-between;
align-items: center;
`

const TopBar = () => {
    const { state } = useStore()
    console.log(state);
    return <Container>
        <span>There are {state.users && state.users.length} users online</span>
        <span>{state.myUsername} ({state.myId && state.myId.substring(0, 3)})</span>
    </Container>
}

export default TopBar

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStore } from './SocketHook';
import { useParams } from 'react-router-dom';
import MessageTree from './components/MessageTree';

const ChannelTitle = styled.h2`
margin-bottom: 25px;
`

const Channel = () => {
    const { state } = useStore()
    const { channel } = useParams();
    const [channelName, setChannelName] = useState(null);

    useEffect(() => {
        const userOfChannel = state.users.find(user => user.id === channel)
        if(userOfChannel) setChannelName(userOfChannel.username)
    }, [state.users, channel])
    
    return <>
        { channelName && <ChannelTitle>Send a message to {channelName}</ChannelTitle>}
        <MessageTree />
    </>
}

export default Channel

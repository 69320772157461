import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStore } from '../SocketHook';

const Container = styled.header`
padding: 20px;
box-sizing: border-box;
width: 100%;
color: #fff;
position: absolute;
display: block;
bottom: 0;
left: 0;
right: 0;
background: rgb(230,222,225);
`

const Input = styled.input`
display: block;
width: 100%;
border: 1px solid #eee;
border-radius: 6px;
padding: 10px;
outline: none;

&:active,
&:focus {
    outline: none;
    border-color: rgb(80,50,60);
}
`

const ChatInput = () => {

    const { state } = useStore();
    const { channel } = useParams();

    const handleKeyUp = event => {
        if(event.keyCode === 13) {
            console.log(channel, event.target.value);
            state.socket.emit("dm", channel, event.target.value);
            event.target.value = '';
        }
    }

    return <Container>
        <Input onKeyUp={handleKeyUp} disabled={!channel} placeholder={`send message to ${channel}`}/>
    </Container>
}

export default ChatInput

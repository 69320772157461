import { useStore } from "../SocketHook"
import Message from "./Message";

const MessageHistory = ({ channel }) => {

    const { state } = useStore();

    return <>
        {channel && state.rooms[channel] && state.rooms[channel].messages.map((dm, key) => <Message key={key} {...dm} /> )}
    </>
}

export default MessageHistory
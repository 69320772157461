import React from 'react';
import { Outlet } from 'react-router-dom';
import ChatInput from './components/ChatInput';

const General = () => {
    return <>
        <Outlet />
        <ChatInput />
    </>
}

export default General
